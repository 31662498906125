<template>
  <v-card elevation="2" color="primary" dark rounded>
    <v-card-title class="text-h4" style="font-family: 'Work Sans' !important;">
      {{ value }}
      <template v-if="currencyCode === 'try' || currencyCode === ''">
        ₺
      </template>
      <template v-if="currencyCode === 'usd'">
        $
      </template>
      <template v-if="currencyCode === 'eur'">
        €
      </template>
    </v-card-title>

    <v-card-text>
      <v-progress-linear :value="(value * 100) / max" color="secondary" />
    </v-card-text>

    <v-card-subtitle
      class="text-h6"
      style="font-family: 'Work Sans' !important;"
    >
      {{ $t(title) }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'CustomProgressBar',
  props: ['max', 'value', 'title', 'currencyCode']
}
</script>
