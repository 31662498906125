<template>
  <v-menu
    v-model="fromDateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <v-date-picker
      locale="tr-tr"
      :value="value"
      no-title
      @input="filterStartDate"
    ></v-date-picker>
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        outlined
        readonly
        :value="value"
        v-on="on"
        hide-details
      >
        <template v-slot:prepend-inner>
          <v-icon v-on="on">mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
  </v-menu>
</template>

<script>
export default {
  name: 'CustomDatePicker',
  props: ['value', 'label', 'customRef', 'dateErr'],
  data: () => ({
    fromDateMenu: false
  }),

  methods: {
    filterStartDate(val) {
      this.fromDateMenu = false
      this.$emit('onselect', val, this.customRef)
    }
  }
}
</script>
