<template>
  <v-container>
    <!--    <v-row>
      <v-col>
        <CustomProgressBar
          title="pages.balance.query_balance"
          :value="table.merchantsTotal"
          :max="table.transactionsTotal"
        />
      </v-col>
      <v-col>
        <CustomProgressBar
          title="pages.balance.transactions_total"
          :value="table.transactionsTotal"
          :max="table.transactionsTotal"
        />
      </v-col>
      <v-col>
        <CustomProgressBar
          title="pages.balance.totalBalance"
          :value="totalBalance"
          :max="totalBalance"
        />
      </v-col>
    </v-row>-->
    <v-row>
      <v-col>
        <v-data-table
          :headers="tableHeaders"
          :items="table.items"
          :options.sync="options"
          :server-items-length="table.count"
          :loading-text="$t('table.commons.loading')"
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100],
            itemsPerPageText: $t('tables.commons.itemsPerPageText')
          }"
          :no-data-text="$t('tables.commons.no_data')"
          disable-sort
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t('pages.titles.refund_requests') }}
              </v-toolbar-title>
              <v-spacer />
              <v-menu
                v-model="showFilter"
                bottom
                left
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        icon
                      >
                        <v-icon>
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Filtre</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <CustomDatePicker
                        :value="query.requestDate"
                        customRef="requestDate"
                        :label="
                          $t('tables.headers.refund_requests.requestDate')
                        "
                        @onselect="filterStartDate"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <CustomDatePicker
                        :value="query.transactionDate"
                        customRef="transactionDate"
                        :label="
                          $t('tables.headers.refund_requests.transactionDate')
                        "
                        @onselect="filterStartDate"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        v-model="query.methodId"
                        :items="methods"
                        hide-details
                        :label="$t('tables.headers.refund_requests.methodName')"
                        outlined
                      ></v-select>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        v-model="query.currencyCode"
                        hide-details
                        :items="currencyOptions"
                        :label="$t('tables.commons.currency_code')"
                        outlined
                      ></v-select>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        :label="
                          $t('tables.headers.refund_requests.transactionId')
                        "
                        v-model="query.transactionId"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        :label="$t('tables.headers.refund_requests.orderId')"
                        v-model="query.orderId"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        :label="$t('tables.headers.refund_requests.customerId')"
                        v-model="query.customerId"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        :label="
                          $t('tables.headers.refund_requests.customerEmail')
                        "
                        v-model="query.customerEmail"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        :items="status"
                        v-model="query.refundStatus"
                        :label="
                          $t('tables.headers.refund_requests.refundStatus')
                        "
                        outlined
                        hide-details
                      ></v-select>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-btn block color="primary" @click="getRefundList(true)">
                        {{ $t('tables.commons.filter') }}
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-chip-group class="px-4 pb-4">
              <template v-for="(value, name, i) in query">
                <v-chip
                  close
                  @click:close="removeChip(name)"
                  :key="i"
                  v-if="value !== '' && value"
                  color="primary"
                  dark
                  :label="true"
                >
                  {{ $t('tables.headers.refund_requests' + '.' + name) }}
                  {{ ' : ' }}
                  <template v-if="name === 'refundStatus'">
                    {{ $t('status' + '.' + enumStatus[value]) }}
                  </template>
                  <template v-if="name === 'methodId'">
                    {{ $t(enumMethods[value]) }}
                  </template>
                  <template v-if="!['refundStatus', 'methodId'].includes(name)">
                    {{ value }}
                  </template>
                  <!-- <template v-if="name === 'paymCatId'">
                    {{ `${$t('pages.balance.' + enumQuery[name])}: ` }}
                    {{
                      $t('payment_categories.' + choose_payment_category(value))
                    }}
                  </template>
                  <template v-if="!(name === 'paymCatId')">
                    {{ `${$t('pages.balance.' + enumQuery[name])}: ${value}` }}
                  </template> -->
                </v-chip>
              </template>
              <v-chip
                close
                v-if="showClearChips"
                color="primary"
                dark
                :label="true"
                @click:close="removeChip()"
              >
                {{ $t('tables.commons.clear_filters') }}
              </v-chip>
            </v-chip-group>
          </template>
          <template v-slot:item.methodName="{ item }">
            {{ item.methodName }}

            {{
              $t(
                `payment_categories_2.${choose_payment_category(
                  item.methodCategoryId
                )}`
              )
            }}
          </template>
          <template v-slot:item.refundStatus="{ item }">
            {{ $t('status' + '.' + enumStatus[item.refundStatus]) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="dateErr" color="red" timeout="3000" top right>
      {{ $t('errors.min_date_error') }}
    </v-snackbar>
  </v-container>
</template>

<script>
/* eslint-disable */
import requests from '@/mixins/requests'
import choose from '@/mixins/choose'
import CustomDatePicker from '@/components/CustomDatePicker'
import CustomProgressBar from '@/components/CustomProgressBar'

export default {
  name: 'Balances',

  metaInfo: function() {
    return {
      title: `${this.$t('pages.titles.refund_requests')}`,
      titleTemplate: `%s | PAYMAPI ${this.$t('app')}`
    }
  },
  mixins: [requests, choose],
  components: { CustomDatePicker, CustomProgressBar },
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 20
    },
    table: {
      items: [],
      count: 0,
      merchantsTotal: 0,
      transactionsTotal: 0
    },
    form: {
      requestDate: null,
      transactionId: null,
      transactionDate: null,
      methodName: null,
      methodCategoryId: null,
      orderId: null,
      customerId: null,
      customerEmail: null,
      orderTotal: null,
      currencyCode: null,
      refundStatus: null
    },
    query: {
      requestDate: '',
      transactionId: '',
      transactionDate: '',
      orderId: '',
      customerId: '',
      customerEmail: '',
      currencyCode: '',
      refundStatus: ''
    },
    currencyOptions: [
      { value: 'TRY', text: 'TRY' },
      { value: 'USD', text: 'USD' },
      { value: 'EUR', text: 'EUR' }
    ],
    showFilter: false,
    enumQuery: {
      requestDate: 'requestDate',
      transactionId: 'transactionId',
      transactionDate: 'transactionDate',
      orderId: 'orderId',
      customerId: 'customerId',
      customerEmail: 'customerEmail',
      currencyCode: 'currencyCode',
      refundStatus: 'refundStatus',
      methodId: 'methodName'
    },

    enumStatus: {
      0: 'waiting_for_refund',
      1: 'refund',
      2: 'could_not_refund'
    },
    dateErr: false,
    totalBalance: 0,
    methods: [],
    enumMethods: {}
  }),
  computed: {
    tableHeaders() {
      let _headers = []
      const headers = [
        'requestDate',
        'transactionId',
        'transactionDate',
        'methodName',
        'orderId',
        'customerId',
        'customerEmail',
        'orderTotal',
        'currencyCode',
        'refundStatus'
      ]
      headers.map(item => {
        _headers.push({
          value: item,
          text: this.$t(`tables.headers.refund_requests.${item}`)
        })
      })
      return _headers
    },
    status() {
      return [
        { value: '0', text: this.$t('status.waiting_for_refund') },
        { value: '1', text: this.$t('status.refund') },
        {
          value: '2',
          text: this.$t('status.could_not_refund')
        }
      ]
    },
    showClearChips: function() {
      let flag = false
      Object.values(this.query).map(item => {
        if (item && item !== '') flag = true
      })

      return flag
    },
    paymentCategories: function() {
      const categories = this.payment_categories.map(item => ({
        value: item.id,
        text: this.$t('payment_categories_2.' + item.name)
      }))
      return categories
    }
  },
  watch: {
    options: {
      handler() {
        this.getRefundList()
      },
      deep: true
    },
    query: {
      handler() {
        if (this.query.startDate !== '' && this.query.endDate !== '') {
          if (
            new Date(this.query.startDate).getTime() -
              new Date(this.query.endDate) >
            0
          ) {
            this.query.startDate = ''
            this.dateErr = true
          }
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.getRefundList()
    await this.getMethodList()
  },
  methods: {
    async getMethodList() {
      const response = await this._paymentMethodList()
      console.log(response)
      response.map(item => {
        this.methods.push({
          value: item.methodId,
          text: `${item.methodName} ${this.$t(
            'payment_categories_2' +
              '.' +
              this.choose_payment_category(item.methodCatId, true)
          )}`
        })
        this.enumMethods[item.methodId] = `${item.methodName} ${this.$t(
          'payment_categories_2' +
            '.' +
            this.choose_payment_category(item.methodCatId)
        )}`
      })
    },
    async getBalance() {
      const { balance } = await this._getBalance()
      this.totalBalance = balance
    },
    async getRefundList(isSearchBtn = false) {
      this.showFilter = false

      if (isSearchBtn) {
        this.options.page = 1
      }
      const { data, count } = await this._getRefundList({
        page: this.options.page,
        limit: this.options.itemsPerPage,
        query: this.query
      })
      this.table.items = data
      this.table.count = count
    },
    filterStartDate(value, ref) {
      this.query[ref] = value
    },
    removeChip(name = '') {
      if (name === '') {
        Object.keys(this.query).map(item => {
          item === 'outOnly'
            ? (this.query[item] = false)
            : (this.query[item] = '')
        })
      } else {
        name === 'outOnly'
          ? (this.query[name] = false)
          : (this.query[name] = '')
      }
      this.getRefundList(true)
    }
  }
}
</script>
