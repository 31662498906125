<template>
  <v-container>
    <v-row>
      <v-col>
        <CustomProgressBar
          title="pages.balance.query_balance"
          :value="table.merchantsTotal"
          :max="table.transactionsTotal"
        />
      </v-col>
      <v-col>
        <CustomProgressBar
          title="pages.balance.transactions_total"
          :value="table.transactionsTotal"
          :max="table.transactionsTotal"
        />
      </v-col>
      <v-col>
        <CustomProgressBar
          title="pages.balance.totalBalance"
          :value="totalBalance"
          :max="totalBalance"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="tableHeaders"
          :items="table.items"
          :options.sync="options"
          :server-items-length="table.count"
          :loading-text="$t('table.commons.loading')"
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100],
            itemsPerPageText: $t('tables.commons.itemsPerPageText')
          }"
          :no-data-text="$t('tables.commons.no_data')"
          disable-sort
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t('pages.titles.balance') }}
              </v-toolbar-title>
              <v-spacer />
              <v-menu
                v-model="showFilter"
                bottom
                left
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        icon
                      >
                        <v-icon>
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Filtre</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <CustomDatePicker
                        :value="query.startDate"
                        customRef="startDate"
                        :label="$t('tables.commons.start_date')"
                        @onselect="filterStartDate"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <CustomDatePicker
                        :value="query.endDate"
                        customRef="endDate"
                        :label="$t('tables.commons.end_date')"
                        @onselect="filterStartDate"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        hide-details
                        v-model="query.paymCatId"
                        :items="paymentCategories"
                        :label="$t('tables.commons.categories')"
                        outlined
                        clearable
                      ></v-select>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        v-model="query.currencyCode"
                        hide-details
                        :items="currencyOptions"
                        :label="$t('tables.commons.currency_code')"
                        outlined
                      ></v-select>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-btn block color="primary" @click="getBalances(true)">
                        {{ $t('tables.commons.filter') }}
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @click="getBalances(true)"
                    icon
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Hesap Hareketi Ekle</span>
              </v-tooltip> -->
            </v-toolbar>
            <v-chip-group class="px-4 pb-4">
              <template v-for="(value, name, i) in query">
                <v-chip
                  close
                  @click:close="removeChip(name)"
                  :key="i"
                  v-if="value !== '' && value"
                  color="primary"
                  dark
                  :label="true"
                >
                  <template v-if="name === 'paymCatId'">
                    {{ `${$t('pages.balance.' + enumQuery[name])}: ` }}
                    {{
                      $t('payment_categories.' + choose_payment_category(value))
                    }}
                  </template>
                  <template v-if="!(name === 'paymCatId')">
                    {{ `${$t('pages.balance.' + enumQuery[name])}: ${value}` }}
                  </template>
                </v-chip>
              </template>
              <v-chip
                close
                v-if="showClearChips"
                color="primary"
                dark
                :label="true"
                @click:close="removeChip()"
              >
                {{ $t('tables.commons.clear_filters') }}
              </v-chip>
            </v-chip-group>
          </template>
          <template v-slot:item.paymCatId="{ item }">
            {{
              $t(
                `payment_categories.${choose_payment_category(item.paymCatId)}`
              )
            }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="dateErr" color="red" timeout="3000" top right>
      {{ $t('errors.min_date_error') }}
    </v-snackbar>
  </v-container>
</template>

<script>
/* eslint-disable */
import requests from '@/mixins/requests'
import choose from '@/mixins/choose'
import CustomDatePicker from '@/components/CustomDatePicker'
import CustomProgressBar from '@/components/CustomProgressBar'
export default {
  name: 'Balances',

  metaInfo: function() {
    return {
      title: `${this.$t('pages.titles.balance')}`,
      titleTemplate: `%s | PAYMAPI ${this.$t('app')}`
    }
  },
  mixins: [requests, choose],
  components: { CustomDatePicker, CustomProgressBar },
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 20
    },
    table: {
      items: [],
      count: 0,
      merchantsTotal: 0,
      transactionsTotal: 0
    },
    form: {
      transactionDate: null,
      paymentCategory: null,
      transactionId: null,
      paymCatId: null,
      orderTotal: null,
      currencyCode: null,
      percentage: null,
      merchantBalance: null
    },
    query: {
      startDate: '',
      endDate: '',
      paymCatId: '',
      currencyCode: ''
    },
    currencyOptions: [
      { value: 'TRY', text: 'TRY' },
      { value: 'USD', text: 'USD' },
      { value: 'EUR', text: 'EUR' }
    ],
    showFilter: false,
    enumQuery: {
      startDate: 'start_date',
      endDate: 'end_date',
      currencyCode: 'currency_code',
      paymCatId: 'category'
    },
    dateErr: false,
    totalBalance: 0
  }),
  computed: {
    tableHeaders() {
      let _headers = []
      const headers = [
        'transactionDate',
        'transactionId',
        'paymCatId',
        'orderTotal',
        'currencyCode',
        'percentage',
        'merchantBalance'
      ]
      headers.map(item => {
        _headers.push({
          value: item,
          text: this.$t(`tables.headers.balances.${item}`)
        })
      })
      return _headers
    },
    showClearChips: function() {
      let flag = false
      Object.values(this.query).map(item => {
        if (item && item !== '') flag = true
      })

      return flag
    },
    paymentCategories: function() {
      const categories = this.payment_categories.map(item => ({
        value: item.id,
        text: this.$t('payment_categories.' + item.name)
      }))
      return categories
    }
  },
  watch: {
    options: {
      handler() {
        this.getBalances()
      },
      deep: true
    },
    query: {
      handler() {
        if (this.query.startDate !== '' && this.query.endDate !== '') {
          if (
            new Date(this.query.startDate).getTime() -
              new Date(this.query.endDate) >
            0
          ) {
            this.query.startDate = ''
            this.dateErr = true
          }
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.getBalances()
    await this.getBalance()
  },
  methods: {
    async getBalance() {
      const { balance } = await this._getBalance()
      this.totalBalance = balance
    },
    async getBalances(isSearchBtn = false) {
      this.showFilter = false
      if (isSearchBtn) {
        this.options.page = 1
      }
      const {
        data,
        count,
        merchantsTotal,
        transactionsTotal
      } = await this._getBalances({
        page: this.options.page,
        limit: this.options.itemsPerPage,
        query: this.query
      })
      this.table.items = data
      this.table.count = count
      this.table.merchantsTotal = merchantsTotal
      this.table.transactionsTotal = transactionsTotal
    },
    filterStartDate(value, ref) {
      this.query[ref] = value
    },
    removeChip(name = '') {
      if (name === '') {
        Object.keys(this.query).map(item => {
          item === 'outOnly'
            ? (this.query[item] = false)
            : (this.query[item] = '')
        })
      } else {
        name === 'outOnly'
          ? (this.query[name] = false)
          : (this.query[name] = '')
      }
      this.getBalances(true)
    }
  }
}
</script>
